<template>
  <div>
    <vs-dialog v-model="returnOpenDialog" full-screen>
      <template #header>
        <div class="row" style="width: 100%">
          <div class="col-12 d-flex justify-content-center pt-3">
            <h3 class="c-primary openSansBold">
              {{ curr_document_code[returnLanguage] }}
            </h3>
          </div>

          <div
            class="col-12 px-0 mx-0 d-flex align-items-center justify-content-center mt-2"
          >
            <div
              class="custom-switcher d-flex align-items-center justify-content-center px-4"
            >
              <div
                class="py-2 mx-2"
                style="cursor: pointer"
                v-for="(doc, index) in doc_code_options"
                :key="index + 'docode'"
                @click="sectionSwitcher(doc)"
                :class="[
                  { active: curr_document_code.code === doc.code },
                  `${
                    index == 0 ? 'justify-content-end' : 'justify-content-start'
                  }`,
                ]"
              >
                {{ doc[returnLanguage] }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <div
        class="row"
        ref="tnc_content"
        style="max-height: 70vh; overflow-y: auto;"
      >
        <div
          class="col-12 d-flex justify-content-center"
          style="background: #f2f7ff"
          v-for="(pages, index) in pdf_pages"
          :key="index"
        >
          <canvas
            style="max-width: 100% !important"
            :id="`pdf_canvas_${curr_document_code.code}_${index + 1}`"
          ></canvas>
        </div>
      </div>
    </vs-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    openDialog: Boolean,
  },
  data() {
    return {
      pdf_loading_target: null,
      pdf_pages: 0,
      curr_rendered_page: 0,
      curr_document_code: {
        EN: "Privacy Policy",
        ID: "Kebijakan Privasi",
        code: "PP",
      }, // TOU
      doc_code_options: [
        {
          EN: "Privacy Policy",
          ID: "Kebijakan Privasi",
          code: "PP",
        },
        {
          EN: "Terms of Use",
          ID: "Persyaratan Penggunaan",
          code: "TOU",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["language"]),
    returnLanguage() {
      return this.language;
    },
    returnOpenDialog: {
      get() {
        return this.openDialog;
      },
      set(val) {
        this.$emit("update:openDialog", val);
      },
    },
  },
  methods: {
    sectionSwitcher(doc_code) {
      this.curr_document_code = doc_code;
      this.initiateOpenTNC();
    },
    async initiateOpenTNC() {
      // Access pdfjsLib from globalThis
      const { pdfjsLib } = globalThis;
      console.log("getting packages", pdfjsLib);
      // Set worker source for PDF.js
      pdfjsLib.GlobalWorkerOptions.workerSrc =
        "https://unpkg.com/pdfjs-dist@4.0.379/build/pdf.worker.min.mjs";

      // URL of the PDF you want to render
      this.pdf_loading_target = this.$vs.loading({
        target: this.$refs.tnc_content,
        color: "primary",
        text: "Getting Terms & Condition",
      });
      const pdfUrl =
        this.curr_document_code.code == "PP"
          ? "/documents/pdf/tnc/PrivacyPolicy.pdf"
          : "/documents/pdf/tnc/TermsOfUse.pdf";
      // let _pdf = null;
      //   await pdfjsLib.getDocument(pdfUrl).then((pdf) => {
      //     this.pdf_pages = pdf.numPages;
      //     this.curr_rendered_page = 1;
      //     this.renderPage(pdf);
      //     // _pdf = pdf;
      //   });

      const loadingTask = await pdfjsLib.getDocument(pdfUrl);
      loadingTask.promise.then((pdfDocument) => {
        this.pdf_pages = pdfDocument.numPages;
        this.curr_rendered_page = 1;
        console.log("gettingDocument", pdfDocument.numPages);
        this.renderPage(pdfDocument);
      });
    },

    async renderPage(pdf) {
      console.log("RENDEREDPAGE: ", this.curr_rendered_page);
      await pdf.getPage(this.curr_rendered_page).then((page) => {
        console.log("RENDEREDPAGENESTED: ", this.curr_rendered_page);

        try {
          let canvas = document.getElementById(
            `pdf_canvas_${this.curr_document_code.code}_${this.curr_rendered_page}`
          );
          const context = canvas.getContext("2d");
          const viewport = page.getViewport({ scale: 5 });

          console.log("viewport", viewport);
          canvas.width = viewport.width;
          canvas.height = viewport.height;
          page
            .render({
              canvasContext: context,
              viewport,
            })
            .promise.then(() => {
              const imageData = canvas.toDataURL("image/png");
              console.log("Image data:", imageData);
            });
        } catch (error) {
          console.log("");
          console.error(error);
          this.pdf_loading_target.close();
        }
      });
      if (this.curr_rendered_page < this.pdf_pages) {
        this.curr_rendered_page++;
        this.renderPage(pdf);
      }

      if (this.curr_rendered_page == this.pdf_pages) {
        this.pdf_is_done_loading = true;
        this.pdf_loading_target.close();
      }
    },
  },
  watch: {
    returnOpenDialog(val) {
      if (val) {
        this.initiateOpenTNC();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-switcher {
  border-radius: 20px;
  color: #878787;
  background: #f2f2f2;
  .active {
    color: #003c86;
    transition: all 150ms ease-in-out;
  }
}
</style>
