import rocketImg from "@/assets/others/rocket.png";
export default {
  namespaced: true,
  state: {
    // indonesian
    indonesian: {
      top_nav: {
        search_placeholder: "Cari Perusahaan",
        links: [
          {
            name: "Mengapa Indoinfo",
            path: "/whyindoinfo",
            type: "item",
            with_children: false,
            path_type: "internal_url",
          },
          {
            name: "Layanan Kami",
            path: "/#wwd",
            type: "item",
            with_children: false,
            path_type: "internal_url",
          },
          {
            name: "Artikel",
            path: "/articles",
            type: "item",
            with_children: false,
            path_type: "internal_url",
          },
          {
            name: "Jadwal Acara",
            path: "/events",
            type: "item",
            with_children: false,
            path_type: "internal_url",
          },
          {
            name: "Kontak",
            path: "Contact Sales",
            type: "item",
            with_children: false,
            path_type: "dialog",
          },
          {
            name: "Coba Gratis",
            path: "Register",
            type: "button",
            with_children: false,
            path_type: "dialog",
          },
          {
            name: "Masuk",
            path: "http://portal.indoinfo.co.id/login",
            type: "button",
            with_children: false,
            path_type: "external_url",
          },
        ],
        lang_options: [
          {
            name: "Bahasa Indonesia",
            abbrev: "ID",
          },
          {
            name: "English",
            abbrev: "EN",
          },
        ],
      },
      register_dialog: {
        title: {
          register: "Daftar",
          contact_sales: "Hubungi Sales",
        },
        register_items: {
          header: "Mulailah bisnis Anda dengan",
          outlined_text: "uji coba gratis kami!",
          img: rocketImg,
          inputs: [
            {
              id: "reg_name",
              placeholder: "Nama Anda",
              email_key: "name",
              input: "",
              type: "text",
              icon: "badge",
              output_variable: "_Name_",
            },
            {
              id: "reg_email",
              placeholder: "Email Anda",
              email_key: "email",
              input: "",
              type: "email",
              icon: "mail",
              output_variable: "_Email_",
            },
            {
              id: "reg_phone",
              placeholder: "Nomor telepon Anda",
              email_key: "phone_no",
              input: "",
              type: "number",
              icon: "smartphone",
              output_variable: "_Phone_",
            },
          ],
        },
        contact_items: {
          header: "Punya Pertanyaan? Yuk, mari kami bantu!",
          outlined_text: "",
          img: "",
          inputs: [
            {
              id: "name",
              placeholder: "Nama Anda",
              email_key: "name",
              input: "",
              type: "text",
              icon: "badge",
              output_variable: "_Name_",
            },
            {
              id: "email",
              placeholder: "Email Anda",
              email_key: "email",
              input: "",
              type: "email",
              icon: "mail",
              output_variable: "_Email_",
            },
            {
              id: "phone",
              placeholder: "Nomor telepon Anda",
              email_key: "phone_no",
              input: "",
              type: "number",
              icon: "smartphone",
              output_variable: "_Phone_",
            },
            {
              id: "comment",
              placeholder: "Pesan Anda",
              email_key: "message",
              input: "",
              type: "textarea",
              output_variable: "_Message_",
            },
          ],
          options_title: "Apakah Anda ingin membuat pertemuan dengan tim kami?",
          options_status: "Pertemuan",
          options: [
            {
              id: "checkbox-1",
              name: "checkbox-1",
              value: "Pertemuan",
              unchecked_value: "Tanpa Pertemuan",
              text: "Ya, saya ingin membuat pertemuan.",
            },
            {
              id: "checkbox-2",
              name: "checkbox-2",
              value: "Tanpa Pertemuan",
              unchecked_value: "Pertemuan",
              text: "Tidak, melalui whatsapp sudah cukup.",
            },
          ],
        },
        failed_send_message_email: {
          title: "<b>Email Gagal Terkirim</b>",
          text: "Terjadi kesalahan saat mencoba mengirim permintaan Anda melalui email. Silakan coba lagi nanti.",
        },
        initiate_text_send: {
          title: "<b>🚀Permintaan Terkirim!</b>",
          text: `Kami telah mengirimkan email kepada Anda dan agen kami.
                    Jika Anda tidak menerimanya, silakan periksa folder spam Anda.`,
        },
        notification: {
          title: "🚀Permintaan Terkirim!",
          text: `Kami telah mengirimkan email kepada Anda dan agen kami. Jika
                    Anda tidak menerimanya, silakan periksa folder spam Anda.`,
        },
        notification_error: {
          title: `<b>Email Gagal Terkirim</b>`,
          text: `Terjadi kesalahan saat mencoba mengirim permintaan Anda melalui email. Silakan coba lagi nanti.`,
        },
        validation_error: {
          less_than_required:
            "masih kurang dari minimum yang diperlukan (5 karakter min.)",
          invalid_email_regex: "Email tidak valid",
        },
        buttons: {
          register: "Register",
          send: "Kirim",
        },
        question_login: "Sudah memiliki akun?",
      },
      footer_nav: {
        footer_head: [
          {
            name: "Tentang Indoinfo",
            links: [
              {
                name: "Lokal & Global",
                link: "/#clientbrands",
                type: "internal",
              },
              {
                name: "Mengapa Indoinfo",
                link: "/whyindoinfo",
                type: "internal",
              },
              {
                name: "Testimoni Pelanggan ",
                link: "/#clients",
                type: "internal",
              },
              {
                name: "Mitra Kami",
                link: "/#partners",
                type: "internal",
              },
            ],
          },
          {
            name: "Fitur",
            links: [
              {
                name: "Vendor Selection System",
                link: "/#wwd",
                type: "internal",
              },
              {
                name: "Business Monitoring Alert",
                link: "/#wwd",
                type: "internal",
              },
              {
                name: "iiConnex",
                link: "/#wwd",
                type: "internal",
              },
              {
                name: "iiReport",
                link: "/#wwd",
                type: "internal",
              },
            ],
          },
          {
            name: "Grup",
            links: [
              {
                name: "Tentang Cyberquote",
                link: "https://cyberquote.com/id/",
                type: "external",
              },
              {
                name: "Whistleblow",
                link: "/whistleblow",
                type: "internal",
              },
              {
                name: "Karir",
                link: "",
                type: "dialog",
                choice: "under-maintenance",
                emphasize: "We're Hiring!",
              },
              {
                name: "Kebijakan Privasi & Persyaratan Penggunaan",
                link: "",
                type: "dialog",
                choice: "terms-conditions",
              },
            ],
          },
        ],
      },
    },
    english: {
      top_nav: {
        search_placeholder: "Search Companies",
        links: [
          {
            name: "Why Indoinfo",
            path: "/whyindoinfo",
            type: "item",
            with_children: false,
            path_type: "internal_url",
          },
          {
            name: "Our Services",
            path: "/#wwd",
            type: "item",
            with_children: false,
            path_type: "internal_url",
          },
          {
            name: "Articles",
            path: "/articles",
            type: "item",
            with_children: false,
            path_type: "internal_url",
          },
          {
            name: "Events",
            path: "/events",
            type: "item",
            with_children: false,
            path_type: "internal_url",
          },
          {
            name: "Contact",
            path: "Contact Sales",
            type: "item",
            with_children: false,
            path_type: "dialog",
          },
          {
            name: "Start Free Trial",
            path: "Register",
            type: "button",
            with_children: false,
            path_type: "dialog",
          },
          {
            name: "Login",
            path: "http://portal.indoinfo.co.id/login",
            type: "button",
            with_children: false,
            path_type: "external_url",
          },
        ],
        lang_options: [
          {
            name: "Bahasa Indonesia",
            abbrev: "ID",
          },
          {
            name: "English",
            abbrev: "EN",
          },
        ],
      },
      register_dialog: {
        title: {
          register: "Register",
          contact_sales: "Contact Sales",
        },
        register_items: {
          header: "Start your business ",
          outlined_text: "with our free trial!",
          img: rocketImg,
          inputs: [
            {
              id: "reg_name",
              placeholder: "Your Name",
              email_key: "name",
              input: "",
              type: "text",
              icon: "badge",
              output_variable: "_Name_",
            },
            {
              id: "reg_email",
              placeholder: "Your Email",
              email_key: "email",
              input: "",
              type: "email",
              icon: "mail",
              output_variable: "_Email_",
            },
            {
              id: "reg_phone",
              placeholder: "Your Phone Number",
              email_key: "phone_no",
              input: "",
              type: "number",
              icon: "smartphone",
              output_variable: "_Phone_",
            },
          ],
        },
        contact_items: {
          header: "Got Questions? Let us help you!",
          outlined_text: "",
          img: "",
          inputs: [
            {
              id: "name",
              placeholder: "Your Name",
              email_key: "name",
              input: "",
              type: "text",
              icon: "badge",
              output_variable: "_Name_",
            },
            {
              id: "email",
              placeholder: "Your Email",
              email_key: "email",
              input: "",
              type: "email",
              icon: "mail",
              output_variable: "_Email_",
            },
            {
              id: "phone",
              placeholder: "Your Phone Number",
              email_key: "phone_no",
              input: "",
              type: "number",
              icon: "smartphone",
              output_variable: "_Phone_",
            },
            {
              id: "comment",
              placeholder: "Your Message",
              email_key: "message",
              input: "",
              type: "textarea",
              output_variable: "_Message_",
            },
          ],
          options_title: "Would you like to make an appointment with our team?",
          options_status: "Appointment",
          options: [
            {
              id: "checkbox-1",
              name: "checkbox-1",
              value: "Appointment",
              unchecked_value: "No Appointment",
              text: "Yes, please make an appointment.",
            },
            {
              id: "checkbox-2",
              name: "checkbox-2",
              value: "No Appointment",
              unchecked_value: "Appointment",
              text: "No thanks, via whatsapp is enough.",
            },
          ],
        },
        failed_send_message_email: {
          title: "<b>Email Failed to be Sent</b>",
          text: "An error occurred while trying to email your request. Please try again later.",
        },
        initiate_text_send: {
          title: "<b>🚀Request Sent!</b>",
          text: `We have emailed you and our agents will follow up with you shortly. 
                    If you do not receive our email, please check within your spam folder.`,
        },
        notification: {
          title: "🚀Request Sent!",
          text: `We have emailed you and our agents will follow up with you shortly. 
                    If you do not receive our email, please check within your spam folder.`,
        },
        notification_error: {
          title: `<b>Email Gagal Terkirim</b>`,
          text: `An error occurred while trying to email your request. Please try again later.`,
        },
        validation_error: {
          less_than_required:
            "is lesser than the required minimum characters (5 minimum)",
          invalid_email_regex: "Email is not valid",
        },
        buttons: {
          register: "Register",
          send: "Send",
        },
        question_login: "Already have an account?",
      },
      footer_nav: {
        footer_head: [
          {
            name: "About Indoinfo",
            links: [
              {
                name: "Local & Global",
                link: "/#clientbrands",
                type: "internal",
              },
              {
                name: "Why Indoinfo",
                link: "/whyindoinfo",
                type: "internal",
              },
              {
                name: "Client Reviews",
                link: "/#clients",
                type: "internal",
              },
              {
                name: "Our Partners",
                link: "/#partners",
                type: "internal",
              },
            ],
          },
          {
            name: "Features",
            links: [
              {
                name: "Vendor Selection System",
                link: "/#wwd",
                type: "internal",
              },
              {
                name: "Business Monitoring Alert",
                link: "/#wwd",
                type: "internal",
              },
              {
                name: "iiConnex",
                link: "/#wwd",
                type: "internal",
              },
              {
                name: "iiReport",
                link: "/#wwd",
                type: "internal",
              },
            ],
          },
          {
            name: "Grup",
            links: [
              {
                name: "About Cyberquote",
                link: "https://cyberquote.com/id/",
                type: "external",
              },
              {
                name: "Whistleblow",
                link: "/whistleblow",
                type: "internal",
              },
              {
                name: "Career",
                link: "",
                type: "dialog",
                choice: "under-maintenance",
                emphasize: "We're Hiring!",
              },
              {
                name: "Privacy Policy & Terms of Use",
                link: "",
                type: "dialog",
                choice: "terms-conditions",
              },
            ],
          },
        ],
      },
    },
  },
  getters: {
    english: (s) => s.english,
    indonesian: (s) => s.indonesian,
  },
};
