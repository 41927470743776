<template>
  <div class="container py-5" style="width: 100% !important">
    <div class="row">
      <div class="col-12 boi-card py-5 d-flex justify-content-center">
        <div class="row">
          <div
            class="col-md-3 col-12 d-flex align-items-center justify-content-center"
          >
            <img src="@/assets/others/boi_report.svg" alt="" />
          </div>
          <div class="col-md-9 col-12">
            <div class="row py-4 px-3">
              <div class="col-12">
                <h2 class="openSansBold">
                  {{ left_splitted_header
                  }}<span class="emphasis">{{ outlined_text }}</span
                  >{{ right_splitted_header }}
                </h2>
              </div>
              <div class="col-12">{{ description }}</div>
              <div class="col-md-6 col-12 py-3">
                <button
                  class="btn btn-block mr-3 btn-outline-primary"
                  @click="openDialog()"
                >
                  {{ cta_button }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vs-dialog v-model="dialog" prevent-close>
      <div class="row py-4 px-3">
        <div class="col-12 d-flex justify-content-center text-center">
          <h2 class="openSansBold black">
            {{ left_splitted_header
            }}<span class="emphasis">{{ outlined_text }}</span
            >{{ right_splitted_header }}
          </h2>
        </div>
        <div class="col-12 d-flex justify-content-center text-center">
          {{ description }}
        </div>
        <div class="col-12 d-flex justify-content-center py-2">
          <img
            height="280px"
            width="auto"
            src="@/assets/others/report_boi_sample.png"
            alt=""
          />
        </div>
        <div class="col-12 py-2 d-flex justify-content-center">
          <vs-select :placeholder="drop_down_label" v-model="file">
            <vs-option
              v-for="(file, index) in files"
              :key="index"
              :label="file.label"
              :value="file.id"
            >
              {{ file.label }}
            </vs-option>
          </vs-select>
        </div>
        <div class="col-12 py-2 d-flex justify-content-center">
          <button
            class="btn btn-block btn-primary"
            @click="openPDF()"
            :disabled="file === ''"
          >
            {{ cta_button }}
          </button>
        </div>
      </div>
    </vs-dialog>
  </div>
</template>

<script>
export default {
  props: {
    header: String,
    outlined_text: String,
    description: String,
    cta_button: String,
    drop_down_label: String,
  },
  data() {
    return {
      left_splitted_header: "",
      right_splitted_header: "",
      outlined_header: "",
      dialog: false,
      files: [
        {
          id: 26,
          label: "Quarter 4 2024 English",
          value: "Q4_2024_EN",
        },
        {
          id: 25,
          label: "Quarter 4 2024 Bahasa Indonesia",
          value: "Q4_2024_ID",
        },
        {
          id: 24,
          label: "Quarter 3 2024 English",
          value: "Q3_2024_EN",
        },
        {
          id: 23,
          label: "Quarter 3 2024 Bahasa Indonesia",
          value: "Q3_2024_ID",
        },
        {
          id: 22,
          label: "Quarter 2 2024 English",
          value: "Q2_2024_EN",
        },
        {
          id: 21,
          label: "Quarter 2 2024 Bahasa Indonesia",
          value: "Q2_2024_ID",
        },
        {
          id: 20,
          label: "Quarter 1 2024 English",
          value: "Q1_2024_EN",
        },
        {
          id: 19,
          label: "Quarter 1 2024 Bahasa Indonesia",
          value: "Q1_2024_ID",
        },
        {
          id: 18,
          label: "Quarter 4 2023 English",
          value: "Q4_2023_EN",
        },
        {
          id: 17,
          label: "Quarter 4 2023 Bahasa Indonesia",
          value: "Q4_2023_ID",
        },
        {
          id: 16,
          label: "Quarter 3 2023 English",
          value: "Q3_2023_EN",
        },
        {
          id: 15,
          label: "Quarter 3 2023 Bahasa Indonesia",
          value: "Q3_2023_ID",
        },

        {
          id: 14,
          label: "Quarter 2 2023 English",
          value: "Q2_2023_EN",
        },
        {
          id: 13,
          label: "Quarter 2 2023 Bahasa Indonesia",
          value: "Q2_2023_ID",
        },
        {
          id: 12,
          label: "Quarter 1 2023 English",
          value: "Q1_2023_EN",
        },
        {
          id: 11,
          label: "Quarter 1 2023 Bahasa Indonesia",
          value: "Q1_2023_ID",
        },
        {
          id: 10,
          label: "Quarter 1 2023 English",
          value: "Q1_2023_EN",
        },
        {
          id: 9,
          label: "Quarter 1 2023 Bahasa Indonesia",
          value: "Q1_2023_ID",
        },
        {
          id: 8,
          label: "Quarter 4 2022 English",
          value: "Q4_2022_EN",
        },
        {
          id: 7,
          label: "Quarter 4 2022 Bahasa Indonesia",
          value: "Q4_2022_ID",
        },
        {
          id: 6,
          label: "Quarter 3 2022 English",
          value: "Q3_2022_EN",
        },
        {
          id: 5,
          label: "Quarter 3 2022 Bahasa Indonesia",
          value: "Q3_2022_ID",
        },
        {
          id: 4,
          label: "Quarter 2 2022 English",
          value: "Q2_2022_EN",
        },
        {
          id: 3,
          label: "Quarter 2 2022 Bahasa Indonesia",
          value: "Q2_2022_ID",
        },
        {
          id: 2,
          label: "Quarter 1 2022 English",
          value: "Q1_2022_EN",
        },
        {
          id: 1,
          label: "Quarter 1 2022 Bahasa Indonesia",
          value: "Q1_2022_ID",
        },
      ],
      file: "",
    };
  },
  methods: {
    CutandDump() {
      if (this.findWordandReturn) {
        const texts = this.header.split(this.outlined_text);
        this.left_splitted_header = texts[0];
        this.right_splitted_header = texts[1];
        this.outlined_header = this.outlined_text;
        console.log(
          "left: ",
          this.left_splitted_header,
          " |right: ",
          this.right_splitted_header,
          " |center:",
          this.outlined_header
        );
      } else {
        console.log("CUTE AND DUMP: could not find");
      }
    },
    openDialog() {
      this.dialog = true;
    },
    openPDF() {
      // return the object in files equal to file id
      this.files.forEach((file) => {
        if (file.id === this.file) {
          console.log("pdf", file.value);
          window.open(
            "./documents/pdf/boi/" + file.value + ".pdf",
            "_blank",
            "fullscreen=yes"
          );
        }
      });
    },
  },
  computed: {
    findWordandReturn() {
      if (this.header.includes(this.outlined_text)) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    header() {
      this.CutandDump();
    },
  },
  mounted() {
    this.CutandDump();
  },
};
</script>

<style lang="scss" scoped>
.boi-card {
  // background-color: #f2f7ff;
  border: none;
  cursor: pointer;
  transition: transform ease 300ms;
  box-shadow: 0px 0px 10px #0000001f;
  border-radius: 10px;
  font-size: 15px;
  min-height: 180px;
  min-width: 100%;
  max-width: 200px;
}

.boi-card:hover {
  transform: translate(0, -10px);
  box-shadow: 5px -5px 10px #0000001f;
}
</style>
